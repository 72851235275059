import { render, staticRenderFns } from "./videoPerformance.vue?vue&type=template&id=ad6bfa04&scoped=true&"
import script from "./videoPerformance.vue?vue&type=script&lang=js&"
export * from "./videoPerformance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad6bfa04",
  null
  
)

export default component.exports